import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入 axios: 网络请求插件
import './utils/request'
import './styles/reset.css'
import 'amfe-flexible/index.js'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
