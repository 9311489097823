<template>
  <div class="home">
    <div class="img1" v-if="imgUrl">
      <img :src="imgUrl" alt="" />
    </div>
    <div v-if="videoUrl">
      <video
      ref="video"
        :src="videoUrl"
        style="width: 100%; max-height: 100%"
        @play="videoPlay"
        @pause="videoPause"
        @ended="videoEnd"
        :controls="false"
        muted
        :enable-progress-gesture="false"
      ></video>
      <audio
        ref="audio"
        v-if="$route.query.type || $route.query.type == 0"
        :src="require(`../assets/${$route.query.type}.mp3`)"
        style="width: 100%; max-height: 100%"
        hidden
      ></audio>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      imgUrl: "",
      videoUrl: "",
      companyShow: true,
    };
  },
  mounted() {
    if (
      this.$route.query.pictureUrl &&
      this.$route.query.pictureUrl.indexOf(".mp4") > -1
    ) {
      this.videoUrl = this.$route.query.pictureUrl;
    } else {
      this.imgUrl = this.$route.query.pictureUrl;
    }
  },
  methods: {
    videoPlay(){
      let audio=this.$refs.audio
      audio.play()
    },
    videoPause(){
      let audio=this.$refs.audio
      audio.pause()
    },
    videoEnd(){
      let audio=this.$refs.audio
      audio.pause()
      audio.currentTime = 0;
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  height: 100vh;
  background: #000;
  display: flex;
  align-items: center;
  .img1 {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
//全屏按钮
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
//进度条
video::-webkit-media-controls-timeline {
  display: none;
}
//观看的当前时间
video::-webkit-media-controls-current-time-display {
  display: none;
}
//剩余时间
video::-webkit-media-controls-time-remaining-display {
  display: none;
}

.video-container {
  touch-action: none;
}
//音量按钮
video::-webkit-media-controls-mute-button {
  display: none;
}
//音量的控制条
video::-webkit-media-controls-volume-slider {
  display: none;
}
</style>